import CustomerFilter from "./customerFilter";

require('./bootstrap');

import Modal from "bootstrap/js/dist/modal";
import Collapse from "bootstrap/js/dist/collapse";
import Dropdown from "bootstrap/js/dist/dropdown";
import Tooltip from "bootstrap/js/dist/tooltip";
import Litepicker from './litepicker';

new CustomerFilter();

let additionalPackages = document.querySelectorAll('.customer-additional-package-wrapper');
if (additionalPackages) {
    additionalPackages.forEach((v) => {
        v.addEventListener('click', () => {
            if (v.classList.contains("disabled")) return;
            let input = v.querySelector('input');
            if (v.classList.contains('active')) {
                console.log("checked");
                input.checked = false;
                v.classList.remove('active');
            } else {
                console.log("not checked");
                input.checked = true;
                v.classList.add('active');
            }
        });
    });
}

let selectPickers = document.querySelectorAll('.selectpicker');
if (selectPickers) {
    selectPickers.forEach((v, k) => {
        if (v.init)
            return;
        v.init = true;

        let select = document.createElement('div');
        select.classList.add("custom-select-picker");
        select.innerHTML = `<i class="far fa-chevron-down "></i>`;

        let options = v.querySelectorAll('option');
        let selected = document.createElement('div');
        selected.classList.add('custom-select-selected');
        selected.innerHTML = "Bitte auswählen";
        select.appendChild(selected);
        let optionWrapper = document.createElement('div');
        optionWrapper.classList.add('custom-select-options');

        selected.addEventListener('click', () => {
            select.classList.toggle('open');
            let height = 0;
            if (select.classList.contains('open')) {
                for (let i = 0; i < optionWrapper.children.length; i++) {
                    height += optionWrapper.children[i].getBoundingClientRect().height;
                }
                if (height > 400) {
                    height = 400;
                }
            }
            optionWrapper.style.maxHeight = `${height + 5}px`;
        });

        window.addEventListener('click', function (e) {
            if (select.contains(e.target)) {
            } else {
                select.classList.remove('open');
                optionWrapper.style.maxHeight = `0`;
            }
        });

        let pickerOptions = [];
        options.forEach((oV, oK) => {
            if (oV.selected) {
                selected.innerHTML = oV.innerHTML;
            }
            if (oV.disabled)
                return;
            let option = document.createElement('div');
            option.classList.add('custom-select-option');
            if (oV.selected) {
                option.classList.add('selected');
            }
            for (let element in oV.dataset) {
                option.dataset[element] = oV.dataset[element];
            }
            option.dataset["value"] = oV.value;
            option.innerHTML = oV.innerHTML + `<i class="far fa-check"></i>`;
            option.addEventListener('click', () => {
                let text = "";

                options.forEach((element) => {
                    if (element.disabled)
                        return;
                    if (v.getAttribute('multiple') === undefined || v.getAttribute('multiple') === null) {
                        element.selected = false;
                    } else {
                        if (element.selected && option.dataset["value"] !== element.value) {
                            if (text)
                                text += ", ";
                            text += element.innerHTML;
                        }
                    }
                });
                pickerOptions.forEach((element) => {
                    if (v.getAttribute('multiple') === undefined || v.getAttribute('multiple') === null) {
                        element.classList.remove('selected');
                    }
                });
                option.classList.toggle('selected');
                options.forEach((element, oK) => {
                    if (element.value === option.dataset["value"]) {
                        element.selected = option.classList.contains('selected');
                        if (element.selected) {
                            if (text)
                                text += ", ";
                            text += element.innerHTML;
                        }
                    }
                });
                if (!text) {
                    text = "Bitte auswählen";
                }
                v.dispatchEvent(new Event('change'));
                selected.innerHTML = text;

                if (v.getAttribute('multiple') === undefined || v.getAttribute('multiple') === null) {
                    select.classList.remove('open');
                    optionWrapper.style.maxHeight = `0`;
                }
            });
            pickerOptions.push(option);
            optionWrapper.appendChild(option);
        });
        select.appendChild(optionWrapper);
        v.parentElement.appendChild(select);
        select.append(v);
    });
}


let deleteModal = document.querySelector("#delete-modal");
if (deleteModal) {
    deleteModal.addEventListener('show.bs.modal', (e) => {
        let input = deleteModal.querySelector("[name='user_id']");
        if (input) {
            input.value = e.relatedTarget.dataset["id"];
        }
        let action = e.relatedTarget.dataset["action"];
        if (action) {
            let form = deleteModal.querySelector('form');
            form.setAttribute('action', action);
        }
    });
}

let unlockModal = document.querySelector("#unlock-modal");
if (unlockModal) {
    unlockModal.addEventListener('show.bs.modal', (e) => {
        let input = unlockModal.querySelector("[name='user_id']");
        input.value = e.relatedTarget.dataset["id"];
    });
}

let lockModal = document.querySelector("#lock-modal");
if (lockModal) {
    lockModal.addEventListener('show.bs.modal', (e) => {
        let input = lockModal.querySelector("[name='user_id']");
        input.value = e.relatedTarget.dataset["id"];
    });
}

let printActivateModal = document.querySelector("#print-active");
if (printActivateModal) {
    printActivateModal.addEventListener('show.bs.modal', (e) => {
        let input = printActivateModal.querySelector("[name='user_id']");
        input.value = e.relatedTarget.dataset["id"];
    });
}

let printDeactivateModal = document.querySelector("#print-deactive");
if (printDeactivateModal) {
    printDeactivateModal.addEventListener('show.bs.modal', (e) => {
        let input = printDeactivateModal.querySelector("[name='user_id']");
        input.value = e.relatedTarget.dataset["id"];
    });
}

let billModal = document.querySelector("#bill-modal");
if (billModal) {
    billModal.addEventListener('show.bs.modal', (e) => {
        let input = billModal.querySelector("[name='user_id']");
        input.value = e.relatedTarget.dataset["id"];
        let select = billModal.querySelector("[name='bill_id']");
        let options = select.querySelectorAll("option");
        options.forEach((v) => {
            if (v.getAttribute('disabled') !== "") {
                v.remove();
            }
        });
        let month = e.relatedTarget.dataset["bills"].split("||||");
        month.forEach((v) => {
            let values = v.split("##");
            if (values.length === 2) {
                select.innerHTML += `<option value="${values[1]}">${values[0]}</option>`;
            }
        });
    });
}

// Get all checkboxes with the class "sync-checkbox"
const monthlyCheckboxes = document.querySelectorAll("input.monthly");
if (monthlyCheckboxes.length) {
    for (let i = 0; i < monthlyCheckboxes.length; i++) {
        monthlyCheckboxes[i].addEventListener("change", function () {
            const isChecked = this.checked;
            for (let j = 0; j < monthlyCheckboxes.length; j++) {
                monthlyCheckboxes[j].checked = isChecked;
                monthlyCheckboxes[j].closest('.cart-product-item-price-input').classList.add("checked");
            }
            for (let j = 0; j < yearlyCheckboxes.length; j++) {
                yearlyCheckboxes[j].closest('.cart-product-item-price-input').classList.remove("checked");
            }

        });
    }
}

// Get all checkboxes with the class "sync-checkbox"
const yearlyCheckboxes = document.querySelectorAll("input.yearly");
if (yearlyCheckboxes.length) {
    for (let i = 0; i < yearlyCheckboxes.length; i++) {
        yearlyCheckboxes[i].addEventListener("change", function () {
            const isChecked = this.checked;
            for (let j = 0; j < yearlyCheckboxes.length; j++) {
                yearlyCheckboxes[j].checked = isChecked;
                yearlyCheckboxes[j].closest('.cart-product-item-price-input').classList.add("checked");
            }

            for (let j = 0; j < monthlyCheckboxes.length; j++) {
                monthlyCheckboxes[j].closest('.cart-product-item-price-input').classList.remove("checked");
            }

        });
    }
}


const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new Tooltip(tooltipTriggerEl)
})


document.querySelectorAll('.has-disabled-fields').forEach((v) => {
        v.addEventListener('submit', function (e) {
            const disabledInputs = v.querySelectorAll(':disabled');
            disabledInputs.forEach(function (input) {
                input.disabled = false;
                input.setAttribute('name', input.name);
            });
        });
    }
);

let datePicker = document.querySelectorAll('.datepicker');
if (datePicker.length) {
    datePicker.forEach(v => {
        let picker = new Litepicker({
            element: v,
            buttonText: {
                previousMonth: `<svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M15 6l-6 6l6 6" /></svg>`,
                nextMonth: `<svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M9 6l6 6l-6 6" /></svg>`,
            },
            format: "DD.MM.YYYY",
            lang: "de-DE",
            onSelect: (date1, date2) => {
                let event = v.getAttribute('name');
                Livewire.emit(event, `${("" + date1.getDate()).padStart(2, "0")}.${("" + (date1.getMonth() + 1)).padStart(2, "0")}.${date1.getFullYear()}`);
            }
        });
    });
}

let addMonitoringButton = document.querySelector('.add-monitoring-button');
if (addMonitoringButton) {
    addMonitoringButton.addEventListener('click', () => {
        let newMonitoring = addMonitoringButton.closest(".monitoring-row").cloneNode(true);
        newMonitoring.querySelectorAll('select').forEach((v) => {
            v.value = "";
        });
        newMonitoring.querySelectorAll('input').forEach((v) => {
            if (v.type === "checkbox") {
                v.checked = false;
                let id = v.id;
                let label = newMonitoring.querySelector(`label[for="${id}"]`);
                let newId = id + Math.floor(Math.random() * 1000);
                v.id = newId;
                label.setAttribute('for', newId);
            }
            if (v.type === "radio") {
                v.checked = false;
                let id = v.id;
                let label = newMonitoring.querySelector(`label[for="${id}"]`);
                let newId = id + Math.floor(Math.random() * 1000);
                v.id = newId;
                label.setAttribute('for', newId);
            }
            if (v.type === "text") {
                v.value = "";
            }
        });
        newMonitoring.querySelector('i').style.opacity = 0;
        addMonitoringButton.closest(".monitoring-row").parentElement.appendChild(newMonitoring);
    });
}
